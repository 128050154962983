import React , { useEffect , useState  }  from 'react';
import './homepage.css';
import {Link} from "react-router-dom";
import RemoveBg from '../../assets/RemoveBg.png';
import FormComponent from './form/forms';
import SliderComponent from './sliders/sliders';
import LatestGameComponent from './latestgame/latestgame';
import FeatureComponent from './featuregame/featuregame';
import BannerComponent from './banner/banner';

import HighlyRatedComponent from './highlyrated/highlyrated';
import TrendingComponent from './trending/trending';
import PopularComponent from './mostpopular/mostpopular';
import VideGameComponent from './videogame/videogame';

import { Swiper, SwiperSlide } from "swiper/react";
import DataService from "../../data.service";
import "swiper/css";
import "swiper/css/navigation"
import SwiperCore, { Autoplay,Navigation} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);

function HomepageComponent(){

    const [settingData, setSettingData] = useState([]);
    const [gameData, setGameData] = useState([]);


    useEffect(() => {
      // DataService.getContent('top-hero-slider')
      // .then(response => {
      //   setGameData(response.data.results);
      // })
      // .catch(e => {
      //     console.log(e);
      // });
      DataService.getContent('website-settings')
      .then(response => {
          setSettingData(response.data);
      })
      .catch(e => {
          console.log(e);
      });
  }, []);
  

  
  
  return (
    <>
    <BannerComponent />    
          {/* <LatestGameComponent /> 
         <PopularComponent />
         <FeatureComponent />
         <VideGameComponent /> */}

{/*      
            <FormComponent />

      <SliderComponent />



     
      <TrendingComponent />
      <HighlyRatedComponent /> */}
    </>
  );

};



export default HomepageComponent;