import React , { useEffect , useState  }  from 'react';
import './header.css';
import {Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import magnifyingGlass from '../../assets/search.svg';
import contactus from '../../assets/mail.svg';
import closeicon from '../../assets/closeicon.svg';
import highrollerFullLogo from '../../assets/new/logo.png';
import MenuIcon from '../../assets/menu.svg';

import mail from '../../assets/new/mail.jpg';
import phone from '../../assets/new/phone.jpg';

import facebook from '../../assets/facebook.png';
import youtube from '../../assets/youtube.png';
import telegram from '../../assets/new/telegram.png';
import whatsapp from '../../assets/new/whatsapp.png';

import DataService from "../../data.service";

import gamepad from '../../assets/game.svg';

function HeaderComponent(props){
  const [showResults, setShowResults] = React.useState(false)
  const [searchText, setSearchText] = React.useState(0)
  const [hideSearchBtn, setHideSearchBtn] = React.useState(false)
  const [lolBg, setlolBg] = useState(0);
  const [settingData, setSettingData] = useState([]);
  const [menuOpenState, setmenuOpenState] =  useState(false)

  const menuOpen = () => {
      setmenuOpenState(!menuOpenState)
  }
  const onClick = () => setShowResults(true)

  const navigate = useNavigate();

  const handleInputChange = event => {
    setSearchText(event.target.value);
   
  };

  const handleKeyDown = (e) => {
    if(e.target.value.length < 3){
      setHideSearchBtn(true)

    }else{
      setHideSearchBtn(false)

    }
    if (e.key === 'Enter') {
      navigate(`/search/${e.target.value}`, { replace: true })
      setSearchText(e.target.value);
    }
  }

  

  useEffect(() => {
    setmenuOpenState(false)
    DataService.getContent('website-settings').then(response => {
        setSettingData(response.data);
    })
    .catch(e => {
        console.log(e);
    });
    
  }, []);


  return (
    <>

        <div className="topHeader">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                  <div className="mailphone">
                      <div><a href={`tel:${settingData.phone_no}`}><img className='mediaLink' src={phone} alt='facebookLink'/>{settingData.phone_no}</a></div>
                      <div><a href={`mailto:${settingData.contact_email}`}><img className='mediaLink' src={mail} alt='facebookLink'/>{settingData.contact_email}</a></div>
                      <div></div>
                  </div>
               
              </div>
              <div className="col-lg-4">
                <div className='socialMedia'>
                <a  target="_blank" rel="noreferrer" href={settingData.facebook_link}><img className='mediaLink' src={facebook} alt='facebookLink'/></a>
                        <a target="_blank" rel="noreferrer" href={settingData.telegram_link}><img className='mediaLink' src={telegram} alt='twitterLink'/></a>
                        <a target="_blank" rel="noreferrer" href={settingData.youtube_channel}><img className='mediaLink' src={youtube} alt='youtubeLink'/></a>
                        <a target="_blank" rel="noreferrer" href={settingData.whatsApp_link}><img className='mediaLink' src={whatsapp} alt='youtubeLink'/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header d-none  d-sm-none d-lg-block">
          <div className="container">
            <div className="row" style={{    alignItems: "center"}}>
                <div className="col-lg-3 col-3">
                     <Link to="/"><img alt="High_Roller_logo" className="w-100"  src={highrollerFullLogo}/></Link>

                    {/* <Link to="/"><img alt="High_Roller_logo" className="desktopLogo"  src={`https://admin.pandamastersweeps.com/media/${lolBg}`}/></Link> */}
                </div>
                <div className="col-lg-2 col-2"></div>

                <div className="col-lg-7 col-7">
                  
                  <div className="menuGrid">
                      <div><Link to="./">Home</Link></div>
                      <div><Link to="./about">About</Link></div>

                      <div><Link to="./offers">Offers</Link></div>
                      <div><Link to="./games">Games</Link></div>
                      <div><Link to="./faqs">Faq's</Link></div>
                      <div><Link to="./contact">Contact</Link></div>
                  </div>
                </div>
            </div>
          </div>
         
            {/* <div className="searchWarpper hideMobile">
                          
              <div className="inputWarpper">
             
                <input type="text"  onKeyDown={handleKeyDown}  onChange={handleInputChange}  placeholder="Search Here" />
              
              </div>
             
              <Link  to={`${hideSearchBtn === false && searchText !== 0 ? `/search/${searchText}` : '#'}`}>
                <button  className='searchButton'  >
               <img alt="IconImage" src={magnifyingGlass}/></button></Link>

            </div> */}

          
        </div>


        <div className="header d-block d-sm-block d-lg-none">
          <div className="container">
            <div className="row" style={{    alignItems: "center"}}>
                <div className="col-lg-5 col-6">
                    <Link to="/" className="mb-0"><img alt="High_Roller_logo" className="w-100"  src={highrollerFullLogo}/></Link>
                </div>
                <div className="col-lg-3 col-2"></div>
               <div className="col-lg-4 col-4">
                 <div className='mobileMenu'>
                    <img onClick={menuOpen} src={MenuIcon} />

                 </div>

               </div>
            </div>
          </div>
        </div>
        <div id="MenuBox" className={`${menuOpenState === true ? 'd-block' : 'd-none'}`}>
          <ul className="mobileList">
          <li><Link  to="./">Home</Link></li>
                            <li><Link  to="./games">Games</Link></li>
                            <li><Link  to="./offers">Offers</Link></li>
                            <li><Link  to="./faqs">Faqs</Link></li>
                            <li><Link  to="/contact">Contact</Link></li>

          </ul>
          
        </div>
      
    </>
  );

};



export default HeaderComponent;