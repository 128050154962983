import React , { useEffect , useState  }  from 'react';
import highrollerFullLogo from '../../assets/PandaLogo.png';

import DataService from "../../data.service";
import {Link} from "react-router-dom";
import facebook from '../../assets/facebook.png';
import telegram from '../../assets/new/telegram.png';
import SvgComponent from '../../assets/compp.jsx';
import eight from '../../assets/new/eighteen.png';

import youtube from '../../assets/youtube.png';

import instagram from '../../assets/instagram.png';
import whatsapp from '../../assets/new/whatsapp.png';
import mail from '../../assets/new/mail.jpg';
import phone from '../../assets/new/phone.jpg';
import './footer.css';


function FooterComponent(){
    const [gameData, setGameData] = useState([]);
    const [settingData, setSettingData] = useState([]);

    const [subscribeData, setSubscribeData] = useState(0);
    const [validationEmail, setValidationEmail] = React.useState(0);

    useEffect(() => {
        // DataService.getAll('highly-rated',5)
        // .then(response => {
        //     setGameData(response.data.results);
  
        // })
        // .catch(e => {
        //     console.log(e);
        // });


        DataService.getContent('website-settings')
        .then(response => {
            setSettingData(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }, []);

    const handleInputChange = event => {
        setSubscribeData(event.target.value);
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(event.target.value);
        if(result===true){
            setValidationEmail(true);
        } else{
            setValidationEmail(false);
        }
      };

    const sendForm = () => {
        var data = {
            email: subscribeData,
        }
        DataService.subscribeData(data)
        .then(response => {
            alert("Subscription Succesfully. Do check your email frequently")
        })
        .catch(e => {
            console.log(e);
        });
    };
    
  return (
    <>
       
       <div className="footer">
           <div className="container">
               <div className="row">
                    <div className="col-lg-4 col-12">
                        <h3>CASINO ROYAL USA</h3>
                        <p>{settingData.site_description}</p>
                        <a target={'_blank'} href="https://www.cgaa.info/?utm_source=google&utm_medium=cpc&utm_campaign=brand&ef_id=EAIaIQobChMIsuzs0rrW9gIVNQqtBh0OPwl3EAAYASAAEgKj2_D_BwE%3AG%3As&s_kwcid=AL%219604%213%21572996268697%21e%21%21g%21%21chumba%20casino%20slot%2114960591273%21125352349661&gclid=EAIaIQobChMIsuzs0rrW9gIVNQqtBh0OPwl3EAAYASAAEgKj2_D_BwE"><img src={eight} alt="eighttenLink" style={{width:'50px'}} /></a>

                        <div className='socialMediaFooter mb-3'>
                        <a  target="_blank" rel="noreferrer" href={settingData.facebook_link}><img className='mediaLink' src={facebook} alt='facebookLink'/></a>
                        <a target="_blank" rel="noreferrer" href={settingData.telegram_link}><img className='mediaLink' src={telegram} alt='twitterLink'/></a>
                        <a target="_blank" rel="noreferrer" href={settingData.youtube_channel}><img className='mediaLink' src={youtube} alt='youtubeLink'/></a>
                        <a target="_blank" rel="noreferrer" href={settingData.whatsApp_link}><img className='mediaLink' src={whatsapp} alt='youtubeLink'/></a>

                        </div>

                    </div>
                    <div className="col-lg-1 col-12"></div>
                    <div className="col-lg-2 col-12">
                        <h3>Links</h3>
                        <ul className='footerList'>
                            <li><Link  to="./">Home</Link></li>
                            <li><Link  to="./games">Games</Link></li>
                            <li><Link  to="./offers">Offers</Link></li>
                            <li><Link  to="./faqs">Faqs</Link></li>
                            <li><Link  to="/contact">Contact</Link></li>

                        </ul>
                    </div>
                    <div className="col-lg-1 col-12"></div>

                    <div className="col-lg-4 col-12">
                        <h3>CONTACT INFO</h3>
                        <div className='mb-3'><a href={`tel:${settingData.phone_no}`}><img className='mediaLinkss' src={phone} alt='facebookLink' style={{" width": "30px", "margin-right": "15  !important" }}/>{settingData.phone_no}</a></div>
                        <div><a href={`mailto:${settingData.contact_email}`}><img className='mediaLinkss' src={mail}   alt='facebookLink' style={{" width": "30px", "margin-right": "15 !important" }}/>{settingData.contact_email}</a></div>
                    </div>

                   
               </div>
           </div>
        </div>

        <div className="copyrightFooter">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <p className='text-center'>{settingData.copyright_text}</p>

                    </div>

                </div>
            </div>
        </div>
        
       
    </>
  );

};



export default FooterComponent;