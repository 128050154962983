

import React  from 'react';
import {Link} from "react-router-dom";

 import './notfound.css';

function NotFound() {
  
  return (
  
    <>    
        <div className="fourofour">
            <div className="container">
                <h3 className='fourofourHeading'>404</h3>
                <p  className='fourofourtext'>The Page you are looking for cannot be found</p>

                <Link to='./' className='fourofourLink'>Back to home</Link>
            </div>

        </div>
    </>

  );
}

export default NotFound;
