import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";

import FormComponent from '../homepage/form/forms';

import './contact.css';

function ContactComponent(){
  const [lolBg, setlolBg] = useState(0);
  const [settingData, setSettingData] = useState([]);

  useEffect(() => {
  

   



DataService.getContent('website-settings')
.then(response => {
    setSettingData(response.data);
})
.catch(e => {
    console.log(e);
});
}, []);

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const [modalIsOpen, setIsOpen] = React.useState(false);

const [validationAlphabet, setValidationAlphabet] = React.useState(false)
const [validationEmail, setValidationEmail] = React.useState(false);
const [fullname, setFullname] = React.useState(false);
const [radioBtn, setRadioBtn] = React.useState('0');

const initalErrmsg = {
  city: "",
email: "",
interest:  "",
message: "",
name:  "",
phone: "",
}
const [errmsg, setErrMsg] = React.useState([]);

const [disabledForm, setDisabledForm] = React.useState(false);

const [requestType, setRequestType] = React.useState('0')
const validateEmail = (email) =>  {
const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
const result = pattern.test(email);

if(result===true){
  setValidationEmail(true);
  setDisabledForm(false)

} else{
  console.log(false)
  setValidationEmail(false);
  setDisabledForm(true)
}
}

const alphabetValidation = (name) => {
var reg_name_lastname = /^[a-zA-Z\s]*$/;
const result = reg_name_lastname.test(name);
if(result===true){
  setValidationAlphabet(true);
  setDisabledForm(false)
}else{
  setValidationAlphabet(false);
  setDisabledForm(true)
}
}

function openModal() {
setIsOpen(true);
}
function closeModal() {
setIsOpen(false);
}


const initialTutorialState = {  
  name: "",
  city: "",
  phone: "",
  email: "",
  interest: "",
  message: "",
  request_type: ""
};


const [tutorial, setTutorial] = useState(initialTutorialState);
const handleInputChange = event => {

  const { name, value } = event.target;
  setTutorial({ ...tutorial, [name]: value });
};
const handleEmailChange = event => {
  validateEmail(event.target.value)
  setTutorial({ ...tutorial, 'email':  event.target.value });
}

const handleNameChange = event =>{
alphabetValidation(event.target.value)
setTutorial({ ...tutorial, 'name':  event.target.value });

}
const personalBtn = () => {
setRadioBtn('personal')

}
const BusinessBtn = () => {
setRadioBtn('business')


}
const sendForm = (event ) => {
  event.preventDefault();

  var data = {
    name: tutorial.name,
    city: tutorial.address,
    email: tutorial.email,
    phone: tutorial.phone,
    interest: tutorial.interest,
    message: tutorial.message,
    request_type:  'null'
  };

  if(tutorial.request_type  === 'Personal'){
    setRadioBtn('personal')
  }else if(tutorial.request_type  === 'Business'){
    setRadioBtn('business')
  }
  setRequestType(tutorial.request_type )
  if(validationEmail){
    console.log("Email Validate")
  }
  
  DataService.create(data).then(response => {   
    setIsOpen(true)
  
  }).catch(e => {
    console.log(e.response.data)
    setErrMsg(e.response.data);
  });
};

    
  return (
    <>
    <div className="contactPage">
      <div className="container">
        <div className='row'>
            <div className="col-lg-6">

          
            
            
            
       



              <form  onSubmit={sendForm}>
                <div className="col-lg-12">
                  <h3 className='contactHeading'>CONTACT US</h3>
                </div>
                <div className="row formGroup">
                  <div className="col-lg-4 col-4">
                    <label>Name:</label>
                  </div>
                  <div className="col-lg-8 col-8">
                    <input required={true} className="inputText"  pattern="[a-zA-Z\s]+"    name="name"  onChange={handleInputChange} type="text" placeholder="Full Name" />
                    <p className="errms">{`${errmsg.name  ? errmsg.name[0] : ' '}`}</p>

                  </div>
                </div>
                <div className="row formGroup">
                  <div className="col-lg-4 col-4">
                    <label>Address:</label>
                  </div>
                  <div className="col-lg-8 col-8">
                      <input required={true}  className="inputText" name="address"  onChange={handleInputChange} type="text" placeholder="Address" />

                      <p className="errms">{`${errmsg.city ? errmsg.city[0] : " "}`}</p>
                  </div>
                </div>
                <div className="row formGroup">
                  <div className="col-lg-4 col-4">
                    <label>Email:</label>
                  </div>
                  <div className="col-lg-8 col-8">
                      <input required={true}  className="inputText" name="email"  onChange={handleInputChange} type="email" placeholder="Email Address" />

                      <p className="errms">{`${errmsg.email  ? errmsg.email[0] : ""}`}</p>
                  </div>
                </div>
                <div className="row formGroup">
                  <div className="col-lg-4 col-4">
                    <label>Ph. No:</label>
                  </div>
                  <div className="col-lg-8 col-8">
                  <input required={true}  type="tel" pattern="[0-9]{10}" className='inputText'  onChange={handleInputChange} name='phone' placeholder="Phone No" />
                  <p className="errms">{`${errmsg.phone ? errmsg.phone[0] : ""}`}</p>
                  </div>
                </div>
                <div className="row formGroup">
                  <div className="col-lg-4 col-4">
                    <label>Interest:</label>
                  </div>
                  <div className="col-lg-8 col-8">
                  <input required={true}  className=" inputText "  pattern="[a-zA-Z]+"    name="interest"  onChange={handleInputChange} type="text" placeholder="Interest" />
                  <p className="errms">{`${errmsg.interest  ? errmsg.interest[0] : ""}`}</p>

                  </div>
                </div>
                <div className="row formGroup">
                  <div className="col-lg-4 col-4">
                    <label>Description:</label>
                  </div>
                  <div className="col-lg-8 col-8">
                    <textarea  required={true}  name='message' className="inputText" onChange={handleInputChange} placeholder="Type your message"></textarea>

                    <p className="errms">{`${errmsg.message  ? errmsg.message[0] : ""}`}</p>

                  </div>
                </div>
                <div className="row formGroup">
                  <div className="col-lg-4 col-4">
                  
                  </div>
                  <div className="col-lg-8 col-8">
                    <input type="submit"  className={`SendBtn ${disabledForm === true ? "d-none" : "d-block"}`}  />
                  </div>
                  
                  <div className={`messageBox ${modalIsOpen === true  ? 'd-block' : 'd-none'}`} > 
              
                  
                  <h3>Your message has been sent successfully</h3>
                 
                </div> 
                </div>
              </form>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 col-6">
                  <img src={`https://admin.casinoroyalusa.com//media/${settingData.telegram_qr_image}`} className="qrImage" />
                </div>
                <div className="col-lg-12 col-6">
                  <img src={`https://admin.casinoroyalusa.com//media/${settingData.whatsApp_qr_image}`}  className="qrImage"/>
                </div>
              </div>
             
            </div>
           
        </div>
      </div>
    
    </div>
      
    </>
  );

};



export default ContactComponent;