import React , { useEffect , useState  }  from 'react';
 import './sliders.css';
import DataService from "../../../data.service";
import {Link} from "react-router-dom";
import fallbacktwo from '../../../assets/fallbacktwo.jpg';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import SwiperCore, { Autoplay,Navigation} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);


const SliderComponent = () => {

  // const handleDragStart = e => e.preventDefault();

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 6 }
  ];

  const [gameData, setGameData] = useState([]);
  const [noofItem, setNoofItem] = useState(0);
  const [lolBg, setlolBg] = useState(0);

  useEffect(() => {
      DataService.getAll('recommended',12).then(response => {
          setGameData(response.data.results); 
          setNoofItem(response.data.count)     
      })
      .catch(e => {
          console.log(e);
      });


  
  }, []);
  return (
    <>

     <div className='recommendedgames'  style={{background: `linear-gradient(180deg, #161108 0%, rgba(0, 0, 0, 0) 29.24%), linear-gradient(180deg, rgba(38, 38, 38, 0) 59.27%, #161108 100%), url(https://admin.highrollersweeps.com/media/${lolBg})`}}>
        
          <div className='container'>
            <h3 className="section-heading">Recommended Games</h3>
                <Swiper  
                autoplay={{"delay": 2500,"disableOnInteraction": false }}  loop={true} navigation={true}  slidesPerView={1} spaceBetween={10}  breakpoints={{
                  "640": { "slidesPerView": 2, "spaceBetween": 20 },
                  "768": { "slidesPerView": 3, "spaceBetween": 40 },
                  "1024": { "slidesPerView": 4, "spaceBetween": 50 },
                  "1800": { "slidesPerView": 5, "spaceBetween": 30 }
                }} className="recommendedSlider">
                {gameData.slice(0, 12).map((item, i) => (
                   <SwiperSlide  key={i}>
                  <Link to={`/gamedetail/${item.id}`} > 
                  <div    className='cardBox'>
                    {item.recommended_section_image ? (<img src={item.recommended_section_image} className='cardBoxImage'  alt="Game_Image"  /> ) : (<img src={fallbacktwo} alt="Lorem" className='cardBoxImage'  />)}                      

                    {/* <img src={item.recommended_section_image} className='cardBoxImage'  alt='' /> */}
                    <div className='game-name'>{item.name}</div>
                  </div>
                  </Link>
                  </SwiperSlide>
                ))}
                   
                </Swiper>
                

             {/* <Carousel  pagination={false} breakPoints={breakPoints} itemPadding={[10,10]} >  
                {gameData.slice(0, 12).map((item, i) => (
                  <Link to={`/gamedetail/${item.id}`}  key={i}> 
                  <div    className='cardBox'>
                    {item.recommended_section_image ? (<img src={item.recommended_section_image} className='cardBoxImage'  alt="Game_Image"  /> ) : (<img src={fallbacktwo} alt="Lorem" className='cardBoxImage'  />)}                      

                    <div className='game-name'>{item.name}</div>
                  </div>
                  </Link>
                ))}
              </Carousel>*/}
          </div>
        
     </div>

   
    <div className='mobileslider recommended sliderbg'>

        <div className='mobileHeader'>
          <h3 className="mobileslider-heading">Recommended Games</h3>
          <Link to="/game/recommended"><button  className="mobileslider-Link">View All</button></Link>

        </div>
        <div style={{"gridTemplateColumns": `repeat(${noofItem}, 139px)`}} className="mobileslider-grid">
          {gameData.slice(0, 12).map((item, i) => (
            <Link to={`/gamedetail/${item.id}`}  key={i}> 
            <div  className='mobileslider-card'>
              {item.recommended_section_image ? (<img src={item.recommended_section_image} className='mobileslider-image'  alt="Game_Image"  /> ) : (<img src={fallbacktwo} alt="Lorem" className='mobileslider-image'  />)}                      

                <div className='mobileslider-title'>{item.name}</div>
            </div>
            </Link>
          ))}
        </div>

    </div>

      
    </>
  );
};

export default SliderComponent;
