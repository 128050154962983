import React , { useEffect , useState  }  from 'react';
import './gamedetail.css';
import DataService from "../../data.service";
import { useParams } from "react-router-dom";

import 'react-image-lightbox/style.css';

import { Helmet } from 'react-helmet';

function GamedetailComponent(){
  const { id } = useParams();

  const [gamedetail, setGameDetail] = useState([]);
  useEffect(() => {
    DataService.getItemDetail('game',id).then(response => {
        setGameDetail(response.data);
        console.log(gamedetail)
    }).catch(e => {
        console.log(e);
    });
    
  }, []);
  return (
      <>

<Helmet>
    <title>{gamedetail.title}</title>

    <meta property='og:title' content={ gamedetail.title} />
    <meta property='og:description' content={ gamedetail.description}/>
    <meta property='og:image' content={ gamedetail.offer_image} />
    </Helmet>
          <div className="gamedetail">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3 className='gamedetailTitle'>{gamedetail.title}</h3>
                  <h4 className='gameSubdescription'>{gamedetail.sub_title}</h4>
                </div>
               
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-12">
                    <img src={gamedetail.image} className="w-100"/>
                </div>
                <div className='col-lg-6  col-12 gameContent'>
                    <p className='text-justify'>{gamedetail.description}</p>
                </div>
                <div className="col-lg-12 col-12 mt-3">
                  <a href={gamedetail.game_link} target="_blank"><button className='playBtn'>PLAY NOW</button></a>

                </div>

              </div>
            </div>
          </div>

      </>
  );

};



export default GamedetailComponent;