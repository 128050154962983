import React , { useEffect , useState  }  from 'react';
import { Link } from 'react-router-dom';
import './trending.css';
import DataService from "../../data.service";
import GameMenu from '../games/Menu/GameMenu';
import fallbackone from '../../assets/fallbackone.jpg';

function TrendingComponent(){
  const [gameData, setGameData] = useState([]);

  const [listView, setlistView] = useState(false);
  const [gridView, setgridView] = useState(true);
  const [dropdownValue, setdropdownValue] = useState(0);

  const listviewfunctions = () => {
    setlistView(true);
    setgridView(false);
  };
  const gridviewfunctions = () => {
    setgridView(true);
    setlistView(false);

  };

  
  useEffect(() => {
    DataService.getAll('trending')
    .then(response => {
        setGameData(response.data.results);
    
    })
    .catch(e => {
        console.log(e);
    });
}, []);



 
  return (
    <>
  <div className='page trendinggameBg'>
    <div className="container">
    <div className="gameMenu">
            <div>
                <h3 className='gameMenuHeading'>Trending Games</h3>
            </div>
            <div>
            <GameMenu gridviewfunction={gridviewfunctions}  listviewfunction={listviewfunctions} />

            </div>
    </div>  
         
    <div className={`${gridView === true ? 'show' : 'hide'}`}>
          <div className='gamegridBox'>
              {gameData.slice(0, 12).map((item, i) => (
               
                  <Link to={`/gamedetail/${item.id}`} key={i}>
                    <div className="CardTypeOne " >
                    <div className={`ribbon ribbon-top-right  ${item.new === true ? 'show' :  'hide'}` }><span>NEW</span></div>

                    {item.image ? (<img src={item.image} alt="Game_Image" className='w-100'  /> ) : (<img src={fallbackone} alt="Lorem" className='w-100' />)}                      

                    <div className='grid'>
                      <div>
                        <h4 className='heading'>{item.name}</h4>
                        <p className='description'> {item.game_type === 1 ? ('Fish' ) : ('')}
                        {item.game_type === 2 ? ('Slot' ) : ('')}
                        {item.game_type === 3 ? ('Keno' ) : ('')}
                        {item.game_type === 4 ? ('Other' ) : ('')}</p>
                      </div>
                      <div>
                        <button className='Btn'>Join Now</button>
                      </div>
                    </div>
                    </div>
                    </Link>
                 
              ))}
          </div>
    </div>
    <div className={`${listView === true ? 'show' : 'hide'}`}>
            {/* <GameListCard dataParentToChild = {gameData}/> */}
           
            <div id='game_list_card'>
              {gameData.slice(0, 12).map((item, i) => (
                <Link key={i} to={`/gamedetail/${item.id}`} className='ListCard'>
                  <div className={`ribbon ribbon-top-right  ${item.new === true ? 'show' :  'hide'}` }><span>NEW</span></div>

                  <div className='ListGrid'>
                    <div>
                    {item.image ? (<img src={item.image} alt="Game_Image" className='listImage w-100'  /> ) : (<img src={fallbackone} alt="Lorem" className='listImage w-100' />)}                      

     
                    </div>
                    <div></div>
                    <div className='GameSection'>
                      <h3 className='ListText'>{item.name}</h3>
                      <p className='ListCategory'> {item.game_type === 1 ? ('Fish' ) : ('')}
                      {item.game_type === 2 ? ('Slot' ) : ('')}
                      {item.game_type === 3 ? ('Keno' ) : ('')}
                      {item.game_type === 4 ? ('Other' ) : ('')}</p>
                      <div className='GameDescriptionBoxTablet'>{item.description}</div>
                    </div>
                    <div className='GameDescriptionBox'>{item.description}</div>
                  </div>
                </Link>
              ))}
            </div>
           
    </div>
    </div>
   
  </div>
 

    </>
  );

};



export default TrendingComponent;