import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";
import { useParams } from "react-router-dom";

import { Helmet } from 'react-helmet';

import './offerdetail.css';


function OfferDetailComponent(){
  const [offerData, setOfferData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    DataService.getItemDetail('offer',id).then(response => {
      setOfferData(response.data);
    }).catch(e => {
        console.log(e);
    });

   
  }, []);


    
  return (
    <>    
    <Helmet>
    <title>{offerData.title}</title>

    <meta property='og:title' content={ offerData.title} />
    <meta property='og:description' content={ offerData.description}/>
    <meta property='og:image' content={ offerData.offer_image} />
    </Helmet>
      <div className="offer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
           
               
              <div className='row rowBorderBottom'>
                  <div className="col-lg-7 col-sm-7 col-12"> 
                      <h3>{offerData.title}</h3>
                      <p className='text-justify'>{offerData.description}</p>
                  </div>
                  <div className="col-lg-5 col-sm-5 col-12">
                  <img src={offerData.offer_image} className="offerImage" />

                  </div>
             </div>
             
         
                      </div>

             
          </div>
        </div>
      </div>
    </>
  );

};



export default OfferDetailComponent;