import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";
import './branddetail.css';
import { useParams } from "react-router-dom";
import {Link} from "react-router-dom";
import fallbackone from '../../assets/new/fallback.jpg';
import { Helmet } from 'react-helmet';

function BrandDetailComponent(){
  const { id } = useParams();


  const [branddetail, setBrandDetail] = useState([]);
  const [games, setGame] = useState([]);

  useEffect(() => {
    DataService.getItemDetail('brand',id).then(response => {
        setBrandDetail(response.data);
    }).catch(e => {
        console.log(e);
    });

    DataService.getBrandGames(id).then(response => {
        setGame(response.data.results);
    }).catch(e => {
        console.log(e);
    });

  }, []);

    
  return (
    <>
        <Helmet>
    <title>{branddetail.title}</title>

    <meta property='og:title' content={ branddetail.title} />
    <meta property='og:description' content={ branddetail.description}/>
    <meta property='og:image' content={ branddetail.offer_image} />
    </Helmet>
    <div className="branddetail">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className='mb-4'>{branddetail.title}</h2>
          </div>
        </div>
        <div className='row'>
         <div className="col-lg-6 col-12">
            
            <img src={branddetail.image} className="branddetailImage w-100"/>
       
         </div>
         <div className="col-lg-6 col-12">
        
            <p className='text-justify brandDetailDescription'>{branddetail.description}</p>
            
         </div>
           
        </div>
      </div>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <br></br>
            <hr className='mt-4'></hr>
            <h3 className='milkywaygameTitle'>Games on {branddetail.title}</h3>

          </div>
        </div>
        <div className="row">

          {games.filter(list => list.brand === Number(id) ).map((item, i) => (
          <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4 branddetailCard">
              <Link to={`/gamedetail/${item.id}`}>
              { item.image ?  <img src={item.image} alt="Game Image" className='w-100'/>  : (<img src={fallbackone} alt="Lorem" className='w-100' />) }

                <h3>{item.title}</h3>
              </Link>
          </div>
          ))}
        </div>
      </div>
    
    </div>
      
    </>
  );

};



export default BrandDetailComponent;