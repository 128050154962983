import React , {  useState  }  from 'react';
import './GameMenu.css';
import grid from '../../../assets/grid-normal.svg';
import list from '../../../assets/list-normal.svg';



const GameMenu = ({ listviewfunction, gridviewfunction}) => {

  const [listViewIcon, setListViewIcon] = useState(false);
  const [gridViewIcon, setGistViewIcon] = useState(true);


  const listView = () => {
    listviewfunction();
    setListViewIcon(true)
    setGistViewIcon(false)
  };
  const gridView = () => {
    gridviewfunction();
    setListViewIcon(false)
    setGistViewIcon(true)
  };

  return (
    <>
      <label className='labelName'>&nbsp;</label>
          <div onClick={gridView}  className='listGridIcon'><img alt="gridListIcon" className={gridViewIcon ? "active" : null} src={grid}  /></div>
          <div onClick={listView}  className='listGridIcon'><img alt="gridListIcon"  className={listViewIcon ? "active" : null} src={list}  /></div>
       
    </>
  );
};

export default GameMenu;
