import http from "./http-common";

class TutorialDataService {
  getItem(link){
    return http.get(`https://admin.casinoroyalusa.com/${link}`);
  }
  getItemNo(link, itemno){
    return http.get(`https://admin.casinoroyalusa.com/${link}?itemNo=${itemno}`);
  }
  getItemDetail(link, id){
    return http.get(`https://admin.casinoroyalusa.com/${link}/${id}`);
  }



  getAll(category, itemNo) {
    if(category){
      return http.get(`https://admin.casinoroyalusa.com/game-${category}/?itemNo=${itemNo}`);

    }else{
      return http.get(`/game/`);

    }
  }

  getBrandGames(id){
      return http.get(`https://admin.casinoroyalusa.com/game/?brand=${id}`);

  }

  getData(category, itemNo) {
    if(category){
      return http.get(`https://admin.casinoroyalusa.com/${category}/?itemNo=${itemNo}`);

    }else{
      return http.get(`/game/`);

    }
  }

  get(id) {
    return http.get(`/game/${id}`);
  }
  getContent(path){
    return http.get(`/${path}`);
  }
  getHeaderBg(){
    return http.get(`/header-background/`);

  }
  getScreenshot(id){
    return http.get(`/game-screenshot/?game_id=${id}`);
  }
  
  getGameType() {
   
      return http.get(`/game-type/`);
    
  }
  gameType(category, itemType) {
    if(itemType && category){
      return http.get(`/game-${itemType}/?type=${category}`);
    }else if(category){
      return http.get(`/game/?type=${category}`);
    }else if(itemType){
      return http.get(`/game-${itemType}`);

    }else{
      return http.get(`/game/`);
    }
  }
  getSearch(string){
    return http.get(`/search/?search=${string}`);
  }
  create(data) {
    return http.post("/contact-us/", data);
  };
  subscribeData(data) {
    return http.post("/newsletter/", data);
  };
  

}

export default new TutorialDataService();