import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";
import {Link} from "react-router-dom";


import './privacy.css';


function PrivacyComponent(){
  const [offerData, setOfferData] = useState([]);

  useEffect(() => {

    DataService.getContent('offer')
        .then(response => {
          setOfferData(response.data.results);
        })
        .catch(e => {
            console.log(e);
        });
}, []);

    
  return (
    <>  
      <div className="offer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            {offerData.map((item, i) => (
                <Link to={`/offers/${item.id}`}  key={i}> 
              <div className='row rowBorderBottom'>
                  <div className="col-lg-7 col-sm-7 col-12"> 
                      <h3>{item.title}</h3>
                      <p className='text-justify'>{item.description}</p>
                  </div>
                  <div className="col-lg-5 col-sm-5 col-12">
                  <img src={item.offer_image} className="offerImage" />

                  </div>
             </div>
             </Link>
            ))}
                      </div>

             
          </div>
        </div>
      </div>
    </>
  );

};



export default PrivacyComponent;