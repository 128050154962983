

import React , { useEffect , useState  }  from 'react';
import { Link } from 'react-router-dom';
// import GameGridCard from './GameGridCard/GameGridCard';
 // import GameListCard from './GameListCard/GameListCard';
 import GameMenu from './Menu/GameMenu';
 import './games.css';
  import './GameListCard.css';
import './GameGridCard.css';
 import SliderComponent from '../homepage/sliders/sliders';
 import DataService from "../../data.service";
 import { useParams } from "react-router-dom";
//  import fallbackone from '../../assets/fallbackone.jpg';
 import fallbackone from '../../assets/new/fallback.jpg';

 import magnify from  '../../assets/magnifyingGlass.svg';

 import gameImg from "../../assets/new/gameImg.jpg"


 
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import SwiperCore, { Autoplay,Navigation} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);



function GamePageComponent() {
  const [gameData, setGameData] = useState([]);
  const [itemm, setItemm] = useState(20);
  const [isFetching, setIsFetching] = useState(false);
  const [itemNo , setItemNo] = useState(0)
  // const [gameType, setGameType] = useState(null);
  // const [newGameType, setNewGameType] = useState(null);

  // const [gameTypee, setGameTypee] = useState(null);
  // const [zeroResult, setZeroResult] = useState(true);

  // const [newgameData, setNewGameData] = useState([]);


  // const [gameCategory, setGameCategory] = useState(0);

  // const [listView, setlistView] = useState(false);
  // const [gridView, setgridView] = useState(true);

  // const listviewfunctions = () => {
  //   setlistView(true);
  //   setgridView(false);
  // };
  // const gridviewfunctions = () => {
  //   setgridView(true);
  //   setlistView(false);

  // };

  const { gametype } = useParams();

  useEffect(() => {
    
      DataService.getItem('brand', itemm)
      .then(response => {
          setGameData(response.data.results);
          setItemNo(response.data.count)
      })
      .catch(e => {
          console.log(e);
      });
    
    
  }, []);


// const loadmore = () =>{
//   setIsFetching(true);

//   setItemm(itemm + 20)
//   DataService.getItem('brand')
//   .then(response => {
//     setIsFetching(false);
//     setGameData(response.data.results);
//   })
//   .catch(e => {
//       console.log(e);
//   });

// }

// useEffect(() => {
//   DataService.gameType(gameTypee, gameCategory)
//   .then(response => {
//       setGameData(response.data.results);
      
//       if(response.data.results.type === '1'){
//         setGameType("Fish")
//       }else if(response.data.results.type === 2){
//         setGameType("Slot")
//       }
//       else if(response.data.results.type === 3){
//         setGameType("Keno")
//       }else if(response.data.results.type === 4){
//         setGameType("Other")
//       }

//       if(response.data.count === 0){
//         setZeroResult(true)
//       }else{
//         setZeroResult(false)
//       }

//   })
//   .catch(e => {
//       console.log(e);
//   });
// }, [gameTypee, gameCategory]);
  

// useEffect(() => {
//   DataService.getGameType( )
//   .then(response => {
//     setNewGameType(response.data.results);
   
//   })
//   .catch(e => {
//       console.log(e);
//   });


//   DataService.getAll()
//   .then(response => {
//       setNewGameData(response.data.results);
//   })
//   .catch(e => {
//       console.log(e);
//   });


// }, []);
  

//   const changeDropdown = (event) =>{
  
//     setGameCategory(event.target.value)

//   }

//   const changeDropdownGameTwo = (event) =>{
//     setGameTypee(event.target.value)
   
//   }


  return (
  
    <>    
      <div className='gameBox'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className='gameTitle'>All Games</h2>
            </div>
          </div>
            <div className='row'>
            {gameData.map((item, i) => (
              <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-6 mb-4 ">
                <Link to={`/brand/${item.id}`}>
                  { item.image ?  <img src={item.image} alt="Game Image" className='w-100'/>  : (<img src={fallbackone} alt="Lorem" className='w-100' />) }
                  
                  <h3>{item.title}</h3>
                  </Link>
              </div>
            ))}
           
            </div>

            {/* <div className="row">
              
              <button id={`${itemNo > itemm ?  'show' :  'hide'}`} className='loadMore'  onClick={loadmore}>{`${isFetching === true ? 'Loading' : 'Load More'}`}</button>
            </div> */}
        </div>
      </div>
    </>

  );
}

export default GamePageComponent;
