import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './faqs.css';
import { Accordion,  AccordionItem,  AccordionItemHeading,  AccordionItemButton,  AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

function FaqsComponent(){
    const [gameData, setGameData] = useState([]);
    const [gameDatatwo, setGameDataTwo] = useState([]);
    const [gameDatathree, setGameDataThree] = useState([]);

    useEffect(() => {
        DataService.getContent('faq' )
        .then(response => {
            setGameData(response.data.results);
            console.log(response.data.results)
        
        })
        .catch(e => {
            console.log(e);
        });
    }, []);


  return (
    <>
    <div className="faqs">
        <div className="container">
            <div className="row">
                {gameData.map((item, i) => (
                <>
                    <div  key={i}  className="col-12 mb-5">
                        <h3>{item.question}</h3>
                        <p className='text-justify'>{item.answer}</p>
                    </div>

                </>
                ))}
               

            </div>
         </div>
    </div>
     
    </>
  );

};



export default FaqsComponent;