import React , { useEffect, useRef , useState  }  from 'react';
import {Link} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import DataService from "../../../data.service";

import "swiper/css";
import "swiper/css/navigation"
import './banner.css';
import bannerImg from "../../../assets/new/banner.jpg";
import gameImg from "../../../assets/new/gameImg.jpg"
import testimonialIcon from "../../../assets/new/testimonialIcon.jpg"
import SvgComponent from "../../../assets/compp.jsx"



import SwiperCore, {
  Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);

const BannerComponent = () => {
  const [gameType, setGameType] = useState([]);
  const [gameFeature, setGamefeature] = useState([]);
  const [gameTestimonial, setGametestimonial] = useState([]);
  const [gameBanner, setGameBanner] = useState([]);

  useEffect(() => {
    DataService.getItem('brand').then(response => {
        setGameType(response.data.results);
    }).catch(e => {
        console.log(e);
    });

    DataService.getItem('site-feature').then(response => {
        setGamefeature(response.data.results);
    }).catch(e => {
        console.log(e);
    });

    DataService.getItem('testimonial').then(response => {
      setGametestimonial(response.data.results);
    }).catch(e => {
        console.log(e);
    });

    DataService.getItem('site-banner').then(response => {
        setGameBanner(response.data.results);
    }).catch(e => {
        console.log(e);
    });

   
}, []);

  return (
    <>

       <div className="bannerSection">
         <div className="container">
         <Swiper   autoplay={{"delay": 2500,"disableOnInteraction": false }}  loop={true} navigation={true} slidesPerView={1}>
          
          {gameBanner.map((item, i) => (
            <SwiperSlide key={i}>
                
                <div className="row">
                    
                    <div className="col-lg-6 col-md-5 col-sm-4 col-12">
                            <img src={item.banner_image} alt="Banner Image"  className='bannerImage'/>
                        </div>
                        <div className="col-lg-6 col-md-7 col-sm-8 col-12">
                            <h2>{item.banner_title}</h2>
                            <h3>{item.banner_content}</h3>
                            <a  target="_blank" href={item.banner_link}><button className='playBtn'>PLAY NOW</button></a>
                        </div>
                  </div>
            </SwiperSlide> 
          ))}


        </Swiper> 
                    
                
         </div>

       </div>






        <div className="onlinegame">
        <div className="container">
            <div className="row">
              <div className="col-12">
              <h2 >Exciting online<br></br> casino style games</h2>

              </div>
            </div>
          </div>

            <div className="container">
            <div className="row">
              {gameType.map((item, i) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-4 ">
                    <Link to={`/brand/${item.id}`}>
                    <img src={item.image} alt="Game Image" className='w-100'/>
                    <h3>{item.title}</h3>
                    </Link>
                </div>
              ))}
            
            
           
            </div>
            </div>
            
        </div>







        <div className="gamefeature">
            <div className="container">
              <div className="row">
                {gameFeature.map((item, i) => (
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12 ">
                    <div className={`featureBox featureBox${i}`}>
                      <div>
                        {item.icon_image ? (<img src={item.icon_image} alt={item.title} /> ): ''}
                        
                        <h3>{item.title}</h3>
                          <p>{item.description}</p>
                          </div>
                    </div>
                     
                  </div>
                ))}
              </div>
            </div>
        </div>




        <div className="testimonial">
          <div className="container">
            <div className="row">
                <img src={testimonialIcon} alt="TestimonialIcon" className='d-block mx-auto' />


                 
          
      <Swiper   autoplay={{"delay": 2500,"disableOnInteraction": false }}  loop={true} navigation={true}   spaceBetween={50} slidesPerView={1}>
   
              {gameTestimonial.map((item, i) => (
                <SwiperSlide>
                    <div className="testimonialBox">
                      <p>{item.description}</p>
                      <img src={item.image} className='testimonialImage' />
                      <h3 className='testimonialName'>{item.name}</h3>
                    </div>
                </SwiperSlide> 
              ))}
     
      
    </Swiper> 

            </div>
          </div>
        </div>
    </>
  );
};



export default BannerComponent;