import React , { useEffect , useState  }  from 'react';
 import './latestgame.css';
import DataService from "../../../data.service";
import {Link} from "react-router-dom";
import popularDefault from '../../../assets/populardefault.png';



import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import SwiperCore, { Autoplay,Navigation} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);





const LatestGameComponent = () => {
 
  // const handleDragStart = e => e.preventDefault();
  const [gameData, setGameData] = useState([]);
  const [noofItem, setNoofItem] = useState(0);
  const [lolBg, setlolBg] = useState(0);

  useEffect(() => {
      DataService.getAll('latest',12)
      .then(response => {
          setGameData(response.data.results);
          setNoofItem(response.data.count)     

      })
      .catch(e => {
          console.log(e);
      });

    
  }, []);
  
 
  return (
    <>
      <div className="homepageLatest">
          <div className="container">
            <div className="row">
                <div className="col-12">
                  <h3 className='homePageLatestTitle'>Latest Release</h3>

                </div>
            </div>
            <div className="row">
            {gameData.slice(0, 6).map((item, i) => (

            <Link  className="col-lg-4 col-sm-6 col-12" to={`/gamedetail/${item.id}`}  key={i}> 
             <div>
                <div className="homepageLatestCard homepageFeatureCard">
                    <img src={item.image} className='w-100' />
                    <div >
                      <h3 className='title'>{item.name}</h3>
                      {/* <p className='cardDescription'>Ocean Monster</p> */}

                      <p className='cardDescription'> {item.game_type === 1 ? ('Fish' ) : ('')}
                          {item.game_type === 2 ? ('Slot' ) : ('')}
                          {item.game_type === 3 ? ('Keno' ) : ('')}
                          {item.game_type === 4 ? ('Other' ) : ('')}</p>
                    </div>
                    
                </div>
              </div>
            
              </Link>
              ))}
            </div>
          </div>
          <div className="container">
            <div className="row">
              <Link className='homepageLatestShowMore' to="./"><button >Show More</button></Link>
            </div>
          </div>
      </div>
  

    </>
  );
};

export default LatestGameComponent;


