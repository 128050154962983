import React , { useEffect , useState  }  from 'react';


import './about.css';
import DataService from "../../data.service";
// anydevice

function AboutComponent(){
  const [gameData, setGameData] = useState([]);
  const [settingData, setSettingData] = useState([]);

  useEffect(() => {
    DataService.getContent('website-settings')
.then(response => {
  console.log(response.data.about_us)
    setSettingData(response.data.about_us);
})
.catch(e => {
    console.log(e);
});
}, []);
  return (
    <>
     <div className="pageBox">
          <div className="container">
          <h3 className='aboutHeading'>About Us</h3>
                    <div className='text-justify brandDetailDescription'  dangerouslySetInnerHTML={{__html: settingData}}></div>

          </div>
    
      </div>
    </>
  );

};



export default AboutComponent;