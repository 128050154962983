import React , { useEffect , useState  }  from 'react';
import FormComponent from '../homepage/form/forms';
import DataService from "../../data.service";
import gameImg from "../../assets/new/gameImg.jpg"


import './store.css';


function StoreComponent(){

  const [lolBg, setlolBg] = useState(0);

  useEffect(() => {
  

    
  
}, []);

    
  return (
    <>
        <div className='brandGame'>
          <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className='brandTitle'>Milkyway</h2>
                </div>
                <div className="col-lg-6">
                    <img src={gameImg} alt="Game Image" className='w-100'/>
                </div>
                <div className="col-lg-6 storeContent">
                <p>Nunc bibendum, odio in molestie viverra, ante lacus sollicitudin eros, vitae efficitur ante arcu ac sapien. Pellentesque risus ligula, sodales at congue nec, laoreet non felis. Vestibulum consequat pulvinar nulla eu sagittis. Duis sodales efficitur ex quis volutpat. Aliquam in fermentum tellus. Nam leo justo, euismod eu urna sed, pretium congue tellus. Nunc erat erat, pretium ut velit ac, malesuada dapibus metus. Nam pellentesque suscipit odio, in malesuada lorem semper vel. Praesent mi enim, vehicula eget vulputate varius, molestie ac sapien. Praesent consectetur arcu quis nisi accumsan, vel ultrices ante venenatis. Nunc blandit finibus placerat. Nulla augue neque, efficitur laoreet turpis ac, mollis vestibulum lectus. Vestibulum lobortis, neque nec mollis consectetur, enim est vulputate leo, vel mattis dolor diam sit amet ante.</p>

                </div>
              </div>
            <div className="row mt-5">
              <div className="col-lg-12">
                <h2 className='gameTitle'>All Games</h2>
              </div>
            </div>
              <div className='row'>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 ">
                    <img src={gameImg} alt="Game Image" className='w-100'/>
                    <h3>Milkyway</h3>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 ">
                    <img src={gameImg} alt="Game Image" className='w-100'/>
                    <h3>Milkyway</h3>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 ">
                    <img src={gameImg} alt="Game Image" className='w-100'/>
                    <h3>Milkyway</h3>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 ">
                    <img src={gameImg} alt="Game Image" className='w-100'/>
                    <h3>Milkyway</h3>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 ">
                    <img src={gameImg} alt="Game Image" className='w-100'/>
                    <h3>Milkyway</h3>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 ">
                    <img src={gameImg} alt="Game Image" className='w-100'/>
                    <h3>Milkyway</h3>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 ">
                    <img src={gameImg} alt="Game Image" className='w-100'/>
                    <h3>Milkyway</h3>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 ">
                    <img src={gameImg} alt="Game Image" className='w-100'/>
                    <h3>Milkyway</h3>
                </div>
              </div>
            
          </div>

        </div>
    </>
  );

};



export default StoreComponent;